<template>
  <footer>
    <div class="content">
      <div class="content_left">
        <div class="content_left_list">
          <a href="https://ajeloan.com/policy/privacy/privacy.html" target="_blank">Privacy Policy</a>
          <!-- <p v-for="(item, index) in $t('footer.listTips')" :key="index">{{ item }}</p> -->
        </div>
      </div>
      <div class="content_right">
        <div class="gp">
          <a href="https://play.google.com/store/apps/details?id=ng.com.ajeloan" target="_blank"><img src="../assets/img/googleplay.png" height="37px"/></a>
        </div>
      </div>
    </div>
    <div class="info">
      <p v-for="(item, index) in $t('footer.listTips')" :key="index">{{ item }}</p>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/footer.scss';
    $list: $icon-1, $icon-3, $icon-4;
    .content_right_list {
      @for $i from 1 to length($list) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list, $i));
        }
      }
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/footer@2x.scss';
    $list: $icon-1-2x, $icon-3-2x, $icon-4-2x;
    .content_right_list {
      @for $i from 1 to length($list) + 1 {
        .item:nth-child(#{$i}) .icon {
          @include sprite(nth($list, $i));
        }
      }
    }
  }
}
footer {
  @include pixel-ratio();
  background-color: #353C3F;
  color: #8b8b8b;
  .info {
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
  }
  p {
    margin: 5px 0;
  }
  a {
    color: white;
  }
  .content {
    padding: 60px 0 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    &_left {
      flex: 1;
      padding-right: 40px;
      .title {
        display: flex;
        max-width: 650px;
        font-size: 20px;
        font-weight: 700;
        a {
          flex: 1;
          text-decoration: none;
          font-weight: 700;
          text-align: left;
          color: #8b8b8b;
          &:last-child {
            text-align: right;
          }
        }
      }
      &_list {
        line-height: 48px;
        text-align: left;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: #8b8b8b;
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
    &_right {
      text-align: left;
    }
  }
}
</style>
